import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const version = '13' // Static version number for cache busting. Update after every player image update.

export const PlayerImage = ({ source, width, display, maxWidth, minWidth, margin }) => {
  const [imgSrc, setImgSrc] = useState('')
  const [webpSrc, setWebpSrc] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const imgSrc = `/img/players/p${source}.png?v=${version}`
    const webpSrc = `/img/players/p${source}.webp?v=${version}`
    setImgSrc(imgSrc)
    setWebpSrc(webpSrc)
    setIsLoaded(false) // Reset loading state when source changes
  }, [source])

  const handleError = () => {
    setImgSrc('/img/players/Photo-Missing.png')
    setWebpSrc('/img/players/Photo-Missing.webp')
  }

  const handleLoad = () => {
    setIsLoaded(true)
  }

  return (
    <picture key={source}>
      <source srcSet={webpSrc} type='image/webp' />
      <source srcSet={imgSrc} type='image/png' />
      {!isLoaded && <Placeholder width={width} display={display} />}
      <PlayerImageStyle
        onError={handleError}
        onLoad={handleLoad}
        src={imgSrc}
        alt='Player'
        width={width}
        display={display}
        maxWidth={maxWidth}
        minWidth={minWidth}
        margin={margin}
        style={{ display: isLoaded ? 'block' : 'none' }} // Hide image until loaded
      />
    </picture>
  )
}

const PlayerImageStyle = styled.img`
  width: ${props => props.width || '100px'};
  display: ${props => props.display || 'block'};
  max-width: ${props => props.maxWidth || '100%'};
  min-width: ${props => props.minWidth || '50px'};
  margin: ${props => props.margin || '0'};
`

const Placeholder = styled.div`
  width: ${props => props.width || '100px'};
  height: ${props => props.width || '100px'}; /* Keep a square aspect ratio */
  display: ${props => props.display || 'block'};
`
