import React from 'react'
import ReactSelect from 'react-select'
import { InputContainer, InputLabels } from './Input.styled'

export default function Select({
  inputLabel,
  placeholder,
  state,
  setState,
  data,
  menuIsOpen,
  setMenuIsOpen,
  whiteLabel = false,
  isMulti = false,
}) {
  let handleMenuOpen, handleMenuClose
  if (setMenuIsOpen) {
    handleMenuOpen = () => setMenuIsOpen(true)
    handleMenuClose = () => setMenuIsOpen(false)
  }

  return (
    <InputContainer>
      <InputLabels whiteLabel={whiteLabel}>{inputLabel}</InputLabels>
      <ReactSelect
        key={state}
        defaultValue={state}
        onChange={setState}
        options={data}
        isMulti={isMulti}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        menuIsOpen={menuIsOpen}
        isSearchable={false}
        placeholder={placeholder}
        styles={{
          option: (defaultStyles, state) => ({
            ...defaultStyles,
            backgroundColor: state.isSelected ? 'var(--color-primary)' : 'inherit',
            fontWeight: state.isSelected ? '800' : 'inherit',
            padding: '1.2rem 1rem',
            borderBottom: '1px solid var(--color-grey-2)',
          }),
          singleValue: defaultStyles => ({
            ...defaultStyles,
            backgroundColor: 'var(--color-primary)',
            width: 'fit-content',
            color: 'white',
            padding: '0.4rem 0.8rem',
            borderRadius: 'var(--box-radius)',
            fontWeight: '600',
          }),
          multiValue: defaultStyles => ({
            ...defaultStyles,
            backgroundColor: 'var(--color-primary)',
            width: 'fit-content',
            color: 'white',
            padding: '0.3rem 0.6rem',
            borderRadius: 'var(--box-radius)',
          }),
          multiValueLabel: defaultStyles => ({
            ...defaultStyles,
            color: 'white',
            fontWeight: '700',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: 'var(--color-grey-1)',
            fontWeight: '500',
            padding: '0.4rem 0',
            transition: '0.3s ease',
            borderRadius: '5px',
            border: state.isFocused
              ? '2px solid var(--color-primary);'
              : '2px solid var(--color-grey-1);',
            boxShadow: state.isFocused
              ? '0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc'
              : 'none',
          }),
        }}
      />
    </InputContainer>
  )
}
