import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import SingleTransfer from './SingleTransfer'
import {
  FilterChipsGrid,
  FilterChipsContainer,
  FilterChips,
} from 'components/design-elements/FilterChips.styled'
import EmptyState, { emptyStateData } from 'views/utility/EmptyState'
import TransferFilter from './TransferFilter'
import SlidingPanel from 'components/patterns/SlidingPanel'
import { coerceToNumber } from 'utils/filterUtils'

export default function TransferHistory() {
  const defaultFilterTags = [
    {
      managers: [],
      players: [],
      gameweeks: [],
      sort: [],
      type: {
        transfer: false,
        private: false,
        auction: false,
      },
    },
  ]

  const { transferHistoryData, open, setOpen } = useOutletContext()
  const transferHistoryDataFormatted = coerceToNumber(
    [...transferHistoryData],
    ['pandl', 'auctionSpend', 'gw']
  )
  const [entries, setEntries] = useState(transferHistoryDataFormatted)
  const [filterTags, setFilterTags] = useState(defaultFilterTags)
  const [gwSelectmenuIsOpen, setGwSelectMenuIsOpen] = useState(false) // this is at this level so that the sliding panel and filter components have access to the state
  const [managerSelectmenuIsOpen, setManagerSelectMenuIsOpen] = useState(false) // this is at this level so that the sliding panel and filter components have access to the state

  const checkIfFiltered = data => {
    return data.some(
      obj =>
        (obj.managers && obj.managers.length > 0) ||
        (obj.players && obj.players.length > 0) ||
        (obj.gameweeks && obj.gameweeks.length > 0) ||
        (obj.sort && obj.sort.length > 0) ||
        (obj.type && Object.values(obj.type).some(value => value))
    )
  }

  const isFiltered = checkIfFiltered(filterTags)

  const createChips = tags => {
    return tags.map(tag => <FilterChips key={tag}>{tag}</FilterChips>)
  }

  const managerTags = createChips(filterTags[0].managers)
  const playerTags = createChips(filterTags[0].players)
  const gameWeekTags = createChips(filterTags[0].gameweeks)
  const sortTags = createChips(filterTags[0].sort)
  const typeTags = Object.keys(filterTags[0].type)
    .filter(key => filterTags[0].type[key])
    .map(tag => <FilterChips key={tag}>{tag}</FilterChips>)

  const transferComponents = entries
    ? [...entries]
        .reverse()
        .map(transfer => (
          <SingleTransfer
            key={transfer.tranId}
            date={transfer.date}
            manager={transfer.manager}
            outImage={transfer.outimage}
            inImage={transfer.inimage}
            firstNameOut={transfer.fnout}
            lastNameOut={transfer.lnout}
            firstNameIn={transfer.fnin}
            lastNameIn={transfer.lnin}
            pandl={transfer.pandl}
            inMil={transfer.buyvalue}
            outMil={transfer.salevalue}
            bidPrice={transfer.bidprice}
            paperVal={transfer.inpaperval}
            type={transfer.type}
            auctionSpend={transfer.auctionSpend}
            gw={transfer.gw}
          />
        ))
    : false

  const showtransfers = entries.length > 0 && entries[0].playerin !== ''

  return (
    <>
      {isFiltered && (
        <FilterChipsGrid>
          <div>Filters:</div>{' '}
          <FilterChipsContainer>
            {managerTags}
            {playerTags}
            {gameWeekTags}
            {typeTags}
            {sortTags}
          </FilterChipsContainer>
        </FilterChipsGrid>
      )}
      {showtransfers ? (
        transferComponents
      ) : (
        <div style={{ height: '60vh' }}>
          <EmptyState
            image={emptyStateData.nothingInList.image}
            alt={emptyStateData.nothingInList.alt}
            text={emptyStateData.nothingInList.text}
          />
        </div>
      )}

      <SlidingPanel
        open={open}
        setOpen={setOpen}
        selectMenuIsOpen={gwSelectmenuIsOpen || managerSelectmenuIsOpen}
      >
        <TransferFilter
          defaultData={transferHistoryDataFormatted}
          setEntries={setEntries}
          setOpen={setOpen}
          setFilterTags={setFilterTags}
          defaultFilterTags={defaultFilterTags}
          gwSelectmenuIsOpen={gwSelectmenuIsOpen}
          setGwSelectMenuIsOpen={setGwSelectMenuIsOpen}
          managerSelectmenuIsOpen={managerSelectmenuIsOpen}
          setManagerSelectMenuIsOpen={setManagerSelectMenuIsOpen}
        />
      </SlidingPanel>
    </>
  )
}
